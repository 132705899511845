import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import axios from "axios";
import { i18n } from "./assets/language/i18n";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import { useModalStore } from "./assets/store/modal/modal_store";
import { useCommonStore } from "./assets/store/setting/common_store";
import { useUserStore } from "@/assets/store/setting/user_store";
import { useWallettApiStore } from "@/assets/store/api/wallet_api_store";

require("./assets/style/css/common.css");

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const app = createApp(App);
app.config.globalProperties.$axios = axios;

app.use(pinia).use(router).use(i18n).mount("#app");

// ============================== Store 설정 ================================
const modalStore = useModalStore(pinia);
const commonStore = useCommonStore(pinia);
const userStore = useUserStore(pinia);
const walletApiStore = useWallettApiStore(pinia);

commonStore.setLanguage(navigator.language);
// walletApiStore.watchAccountEvent();

export { modalStore, commonStore, userStore, walletApiStore };
