import { defineStore } from "pinia";
import { setLanguage } from "@/assets/language/i18n";

export const useCommonStore = defineStore("commonStore", {
  state: () => ({
    language: "",
  }),
  getters: {},
  actions: {
    setLanguage(newLanguage) {
      const languageMap = {
        ko: "ko",
        en: "en",
        zh: "cn",
        ja: "jp",
      };

      const langCode = newLanguage.split("-")[0];

      const mappedLanguage = languageMap[langCode] || "en";

      this.language = mappedLanguage;
      setLanguage(mappedLanguage);
    },
  },
});
