import { defineStore } from "pinia";

export const useUserStore = defineStore("userStore", {
  state: () => ({
    user: {
      admin: false,
      address: "",
    },
  }),
  getters: {},
  actions: {
    setUserAddress(address) {
      this.user.address = address;
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "userStore",
        storage: localStorage,
      },
    ],
  },
});
