import { defineStore } from "pinia";

export const useModalStore = defineStore("modalStore", {
  state: () => ({
    loading_modal: {
      state: false,
    },
    alert_modal: {
      state: false,
      type: "",
      title: "",
      message: "",
    },
    nav_modal: {
      state: false,
    },
    inquiry_modal: {
      state: false,
    },
    car_info_modal: {
      state: false,
      info: {},
    }
  }),
  getters: {},
  actions: {
    showLoadingModal() {
      this.loading_modal.state = true;
    },
    hideLoadingModal() {
      this.loading_modal.state = false;
    },
    showAlertModal(type, title, message) {
      this.alert_modal.state = true;
      this.alert_modal.type = type;
      this.alert_modal.title = title;
      this.alert_modal.message = message;
    },
    hideAlertModal() {
      this.alert_modal.state = false;
    },
    showNavModal() {
      this.nav_modal.state = true;
    },
    hideNavModal() {
      this.nav_modal.state = false;
    },
    showInquiryModal() {
      this.inquiry_modal.state = true;
    },
    hideInquiryModal() {
      this.inquiry_modal.state = false;
    },
    showCarInfoModal(car) {
      this.car_info_modal.state = true;
      this.car_info_modal.info = car;

    },
    hideCarInfoModal() {
      this.car_info_modal.state = false;
    },
  },
});
