import { createI18n } from "vue-i18n";
import ko from "./ko.js";
import en from "./en.js";
import jp from "./jp.js";
import cn from "./cn.js";

const userLanguage = navigator.language.split("-")[0];
const supportedLanguages = ["ko", "en", "jp", "cn"];
const defaultLanguage = supportedLanguages.includes(userLanguage)
  ? userLanguage
  : "en";

export const i18n = createI18n({
  legacy: false,
  locale: defaultLanguage,
  messages: {
    ko,
    en,
    jp,
    cn,
  },
});

export function setLanguage(locale) {
  i18n.global.locale.value = locale;
}
