// ============================== Wallet Connect 기본 설정 =================================
import { createWeb3Modal, defaultWagmiConfig } from "@web3modal/wagmi";
import { polygon, bsc } from "viem/chains";
import { reconnect } from "@wagmi/core";

const projectId = "eb372be0e147fb43b2ae61793fd8a3d3";

const metadata = {
  name: "Web3Modal",
  description: "Web3Modal Example",
  url: "https://web3modal.com",
  icons: ["https://avatars.githubusercontent.com/u/37784886"],
};

const chains = [polygon, bsc];
const chain_id = bsc.id;

const config = defaultWagmiConfig({
  chains,
  projectId,
  metadata,
});

reconnect(config);

createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,
  enableOnramp: true,
  themeMode: "light",
});

const WALLET_SETTING = {
  chain_id,
  config,
};

export { WALLET_SETTING };

// ============================== ABI 기본 설정 =================================
import TOKEN_ABI from "@/assets/abi/token.json";
import USDT_ABI from "@/assets/abi/usdt.json";
import TIMELOCK_ABI from "@/assets/abi/timelock.json";
import PRESALE_ABI from "@/assets/abi/presale.json";
import STAKING_ABI from "@/assets/abi/staking.json";

const WALLET_CONTRACT = {
  TOKEN: {
    abi: TOKEN_ABI["abi"],
    address: TOKEN_ABI["contractAddress"],
  },
  USDT: {
    abi: USDT_ABI["abi"],
    address: USDT_ABI["contractAddress"],
  },
  TIMELOCK: {
    abi: TIMELOCK_ABI["abi"],
    address: TIMELOCK_ABI["contractAddress"],
  },
  PRESALE: {
    abi: PRESALE_ABI["abi"],
    address: PRESALE_ABI["contractAddress"],
  },
  STAKING: {
    abi: STAKING_ABI["abi"],
    address: STAKING_ABI["contractAddress"],
  },
};

export { WALLET_CONTRACT };

// ============================== ABI API =================================
const WALLET_API = {
  TOKEN: {},
  USDT: {},
  TIMELOCK: {},
  PRESALE: {},
  STAKING: {},
};

export { WALLET_API };
