import { defineStore } from "pinia";
import {
  writeContract,
  readContract,
  // watchAccount,
  waitForTransactionReceipt,
} from "@wagmi/core";
import Web3 from "web3";

// ============================== 스토어 & 서비스 ================================
// import { userStore } from "@/main";
import {
  WALLET_SETTING,
  WALLET_CONTRACT,
} from "@/assets/store/api/json/wallet";

export const useWallettApiStore = defineStore("walletApiStore", {
  state: () => ({
    chain_id: WALLET_SETTING.chain_id,
    config: WALLET_SETTING.config,
  }),
  getters: {},
  actions: {
    // ============================== 계정 변경 감지 ================================
    // watchAccountEvent() {
    //   watchAccount(this.config, {
    //     onChange(data) {
    //       userStore.setUserAddress(data.address);
    //       // console.log("Account changed: ", userStore.user.address);
    //     },
    //   });
    // },

    // ============================== 주소 유효성 검사 ================================
    async checkValidAddress(address) {
      try {
        const checkAddress = address.trim();

        if (!checkAddress) {
          return "";
        }

        const isValidAddress = Web3.utils.isAddress(checkAddress);

        return isValidAddress ? checkAddress : false;
      } catch (error) {
        console.error("Error checking valid address:", error);
        return false;
      }
    },

    // ============================== 트랜잭션 대기 ================================
    async waitTransaction(hash) {
      try {
        const transactionReceipt = await waitForTransactionReceipt(
          this.config,
          {
            chainId: this.chain_id,
            hash: hash,
          }
        );

        return transactionReceipt.status === "success";
      } catch (error) {
        console.error("Error waiting for transaction receipt:", error);
        return false;
      }
    },

    // ============================== ABI 함수 호출 =================================
    async callWriteContract(type, method, args) {
      const contract = WALLET_CONTRACT[type];

      if (!contract) {
        throw new Error(`Invalid contract type: ${type}`);
      }

      try {
        const response = await writeContract(this.config, {
          address: contract.address,
          abi: contract.abi,
          functionName: method,
          chainId: this.chain_id,
          args: args,
        });

        await this.waitTransaction(response);

        return response;
      } catch (error) {
        console.error(error);
        return false;
      }
    },

    async callReadContract(type, method, args) {
      const contract = WALLET_CONTRACT[type];

      if (!contract) {
        throw new Error(`Invalid contract type: ${type}`);
      }

      try {
        const response = await readContract(this.config, {
          address: contract.address,
          abi: contract.abi,
          functionName: method,
          chainId: this.chain_id,
          args: args,
        });

        await this.waitTransaction(response);

        return response;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
  },
});
